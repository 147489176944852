<template>
    <div class="home">
        <img class="body-background" src="/ressources/mobile-body-back.png">
        <div class="overlay"></div>
          <div class="home-content">
            <img src="/ressources/net-logo.png" class="net-app-logo">

            <div class="login-formsec">
              <form>
                <div class="log-sumtsec">
                    <div class="loginsec">
                        <input type="text" v-model="form.username" name="username" class="login-input usernm-input" placeholder="Username">
                        <input type="password" v-model="form.password" name="password" class="login-input" placeholder="Password">
                    </div>
                    <div class="submit-sec">
                        <button type="button" :disabled="isLoading" class="login-btn" @click="login">Login</button>
                    </div>
                </div>
              </form>
              <div class="login-footersec">
                  <p v-show="error"   v-text="error" class="msg-txt"></p>
                  <a href="/password-forgot" class="forgt-link">Forgot Password? <span>Click Here</span></a>
                  <a href="/register" class="cret-accnt">Create New Account</a>
              </div>
            </div>
          </div>
    </div>

</template>

<script>
import api from "@/api";

export default {
    name: "Login",
    data() {
        return {
            form: {
                username: "",
                password: ""
            },
            error: "",
            isLoading: false,
            liveVideoId: null,

        };
    },
    mounted() {
        this.updateLiveVideoUrl();
    },
    methods: {
        async login() {
            this.isLoading = true;
            try {

                await api.auth.getCookie();
                let result= await api.auth.login(this.form);
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("userId", result.data.id);
                if(result.data.id){
                 location.href = "/";
                }else{
                    this.error ="The token is expired. Please refresh the page.";
                }

            } catch (err) {
                const errors = err.response.data.errors;
                this.isLoading=false;
                if (err.response.status === 422 && errors) {
                    if(errors["email"]){
                        this.error = errors["email"] ? errors["email"][0] : "";
                    }
                    if(errors["username"]){
                        this.error = errors["username"] ? errors["username"][0] : "";
                    }
                    else{
                        this.error = errors["password"] ? errors["password"][0] : "";
                    }
                } else if (err.response.status === 419) {
                    this.error =
                        "The token is expired. Please refresh the page.";
                }

                else {
                    this.error = err.response.data.message;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async updateLiveVideoUrl() {
            if(localStorage.getItem("userId")){
                const res = await api.user.find({
                    id: localStorage.getItem("userId")
                });
                const user = res.data;
                this.liveVideoId = user.live_video_id;
            }

        }
    }
};
</script>
<style type="text/css">
            *,
        ::before,
        ::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;

        }

        body{
            overflow:hidden;
            font-family: "Open Sans", sans-serif;
        }

        .home {
        height: 100vh;
        position: relative;
        overflow: hidden;
        }
        .body-background{
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        }
        .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        background: rgba(0,0,0,0.3);
        }
        .home-content {
        max-width: 600px;
        position: relative;
        top: 60%;
        color: #fff;
        z-index: 3;
        left: 50%;
        transform: translate(-50%, -60%);
        text-align: center;
        }

        .home-content .net-app-logo {
        width: 200px;
        height: auto;
        }
        .app-logosec {
        width: 300px;
        margin: 0 auto;
        display: flex;
        }
        .login-formsec
        {
        max-width: 80%;
        margin: 0 auto;
        padding: 30px 60px 30px 60px;
        background: #ffffff82;
        }
        .login-formsec input{
        width: 100%;
        color: #fff;
        font-size: 18px;
        outline: none;
        box-shadow: none;
        }
        .log-sumtsec{
        position: relative;
        }
        .submit-sec
        {
        float: right;

        }

        .submit-sec button
        {
        background: #484848;
        border: none;
        padding: 15px;
        width: 70px;
        height: 70px;
        border-radius: 100px;
        font-size: 18px;
        color: #fff;
        }
        .loginsec
        {
        border: 15px solid #1c1e21;
        background: transparent;
        }
        .login-formsec input
        {
        width: 100%;
        padding: 10px;
        background: #1c1e21;
        border: none;
        outline:0
        }
        .login-formsec .usernm-input{
        margin-bottom: 10px;
        }
        .submit-sec
        {
        float: right;
        position: absolute;
        right: -30px;
        bottom: 30px;
        }
        .forgt-link
        {
        display: block;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        margin: 25px 0 15px 0;
        }
        .cret-accnt
        {
        display: block;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;

        }
        .msg-txt
        {
        font-size: 16px;
        margin: 20px 0 0 20px;
        color: #f10707;
        }
        .loginsec .login-input::placeholder{
        color:#f5f5f5;
        font-size: 14px;
        }
        .forgt-link span{
            font-weight:700;
        }
        @media screen and (min-device-width: 300px) and (max-device-width: 500px) {
            .login-formsec
        {
        max-width: 100%;
        margin-top: 30px;

        }
        .submit-sec
        {

        right: -35px;
        bottom: 25px;
        }
        }

    </style>